import { lightDark } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Link } from '@iheartradio/web.accomplice/link';
import { LogotypeSecondary } from '@iheartradio/web.accomplice/logos';
import { Stack } from '@iheartradio/web.accomplice/stack';
import { Text } from '@iheartradio/web.accomplice/text';
import { Link as RouterLink } from '@remix-run/react';

type ForgotResetPasswordHeaderProps = {
  redirectUrl: string;
  mode?: 'forgot-password' | 'reset-password';
};

export const ForgotResetPasswordHeader = ({
  redirectUrl,
  mode = 'forgot-password',
}: ForgotResetPasswordHeaderProps) => {
  const { title, description } =
    mode === 'forgot-password' ?
      {
        title: 'Forgot password?',
        description: 'Enter your email address to reset your password.',
      }
    : {
        title: 'Create a New Password',
        description: null,
      };

  return (
    <Stack
      align="flex-start"
      css={{
        paddingLeft: '$24',
        paddingTop: '$24',
      }}
      gap="$8"
    >
      <Link asChild>
        <RouterLink role="link" to={redirectUrl}>
          <Box asChild height={{ mobile: '2.4rem', large: '3.75rem' }}>
            <LogotypeSecondary aria-label="iHeart Logo" data-test="logo" />
          </Box>
        </RouterLink>
      </Link>
      <Text
        as="h2"
        color={lightDark('$gray600', '$brandWhite')}
        data-test="title"
        kind={{ mobile: 'h3', small: 'h3', medium: 'h2' }}
      >
        {title}
      </Text>
      {description ?
        <Text
          as="p"
          css={{ color: lightDark('$gray500', '$gray200') }}
          data-test="description"
          kind={{ mobile: 'subtitle-4', medium: 'subtitle-2' }}
        >
          {description}
        </Text>
      : null}
    </Stack>
  );
};
